<template>
  <div class="IdiomDetailsS">
    <div class="details" v-show="isDetails">
      <dataAnalysisDetails :isBack="true" ref="dataAnalysisDetails" />

      <!-- <stencilDetails :isBack="true" v-show="!isStencil" ref="stencilDetails" /> -->
    </div>
  </div>
</template>

<script>
//例如：import  from '';
import qs from "qs";
import Cookies from "js-cookie";
import { openLibraryFile } from "api/infoCompile";
import { createLoading } from "utils/utils";
import { getToken } from "@/utils/auth";
import dataAnalysisDetails from "../dataAnalysis/dataAnalysisDetails";
import stencilDetails from "../stencil/stencilDetails";
export default {
  name: "IdiomDetailsS",
  //import引入的组件需要注入到对象中才能使用
  components: {
    dataAnalysisDetails,
    // stencilDetails,
  },
  data() {
    //这里存放数据
    return {
      isDetails: false,
      isStencil: false,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getHref() {
      let url = window.location.search;
      let theRequest = new Object();

      if (url.includes("token=")) {
        let str = url.substr(1);

        let strs = str.split("&");

        // console.log(strs);

        strs.forEach((item, i) => {
          theRequest[item.split("=")[0]] = unescape(item.split("=")[1]);
        });

        let obj = {
          access_token: theRequest.token,
          token_type: "bearer",
        };

        localStorage.setItem("auth_token", JSON.stringify(theRequest.token));
        Cookies.set("auth_token", JSON.stringify(obj), {
          expires: 7,
          path: "/",
          domain: ".writemall.com",
        });

        let data = {
          md5Label: theRequest.textMd5,
          originalId: theRequest?.userId ? theRequest?.userId : "0",
          templateId: "",
        };
        this.handleAuthorize(data);
      }

      //   console.log(theRequest);
    },

    async handleAuthorize(data) {
      let loading = createLoading(".IdiomDetailsS");

      try {
        const res = await openLibraryFile(qs.stringify(data));
        loading.close();
        if (res.code == 200) {
          this.$refs["dataAnalysisDetails"].openLibraryFile = {
            ...res.data.obj,
            html: res.data.html,
            payStatus: res.data.status,
            url: res.data.url,
            viptip: res.data.viptip,
            author: res.data.author,
          };
          this.isDetails = true;
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
        loading.close();
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getHref();
    // console.log(JSON.parse(getToken()));
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang="scss" scoped>
.IdiomDetailsS {
}
</style>
